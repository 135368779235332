








































import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import JwlTaskMeta from '@/components/jwlTaskMeta.vue';
import JwlButton from '@/components/JwlButton.vue';
import { ExtendedRunTask } from '@/store/interface/Task';
import { QuizQuestion as QuizQuestionInterface } from '@/store/interface/Quiz';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';

interface NeedsFixingQuestions {
  index: number;
  question: QuizQuestionInterface;
}

@Component({
  components: {
    JwlSubmitRow,
    JwlButton,
    CommonIcon,
    JwlTaskMeta,
  },
})
export default class QuizEnd extends Vue {
  @Prop(Object)
  taskQuiz!: ExtendedRunTask;

  @Prop(Array)
  taskQuestions!: QuizQuestionInterface[];

  @Prop(Boolean)
  savingData!: boolean

  syncStatus = 0;
  error = null;

  needsFixingHtml (question: NeedsFixingQuestions): string {
    return `${question.index} - ${question.question.question}`;
  }

  publishQuiz (): void {
    if (this.syncStatus !== 1) {
      this.syncStatus = 1;
      this.$store.dispatch('postEmptyData', `task/${this.$route.params.task}/publish`)
        .then((data) => {
          this.$emit('task-published', data);
          this.syncStatus = 2;
        })
        .catch((e) => {
          this.error = e;
          this.syncStatus = -1;
        });
    }
  }

  get isPublished (): boolean {
    return !!this.taskQuiz.submitted && this.taskQuiz.submitted.hasPublished;
  }

  get cannotBePublished (): NeedsFixingQuestions[] {
    const fixAnswers: NeedsFixingQuestions[] = [];

    this.taskQuestions.forEach((question, index) => {
      const isSingleResponseMissing = question.questionType === 'single_response' && question.answerIds && question.answerIds.length === 0;
      const isMultiResponseMissing = question.questionType === 'multiple_response' && question.answerIds && question.answerIds.length < 1;
      const isDragAndDropMissing = question.questionType === 'drag_and_drop' && question.answerDragAndDrop && question.answerDragAndDrop.length < (question.answerOptions.length / 2);
      const isFillTextMissing = question.questionType === 'fill_text' && question.answerString && question.answerString.length === 0;
      if (isSingleResponseMissing || isMultiResponseMissing || isDragAndDropMissing || isFillTextMissing) {
        fixAnswers.push({
          index,
          question,
        });
      }
    });

    return fixAnswers;
  }
}
